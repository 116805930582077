import * as React from 'react';
import {
  FEATURE_FLAGS,
  Navbar,
  useGetAppInitDetails,
  useInjectCobrowseScript,
} from '@highmark-web/highmark-common';
import { useGetUserDropdownConfig } from 'hooks/use-get-user-dropdown-config';
import { useGetAppFeatureFlag } from 'hooks/use-get-app-feature-flag.hook';
import { useGenesisCoreInspector } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';

export const AppNavBarComponent = React.memo(() => {
  const { $tifelse } = useIntl();
  const { qualifiers, brandingInfo } = useGetAppInitDetails();
  const { isEnabled: isCobrowseEnabled } = useGetAppFeatureFlag(
    FEATURE_FLAGS.USER_DROPDOWN_COBROWSE,
  );
  const { isEnabled: isCobSdkEnabled } = useGetAppFeatureFlag(
    FEATURE_FLAGS.COB_SDK,
  );
  useInjectCobrowseScript(isCobrowseEnabled);
  const userDropdownConfig = useGetUserDropdownConfig({
    ...qualifiers,
    isCobrowseEnabled,
    isCobSdkEnabled,
  });

  // Inject the Genesis Core Inspector tool when feature flag is enabled.
  const { isEnabled: isGenesisCoreInspectorEnabled } = useGetAppFeatureFlag(
    FEATURE_FLAGS.ENABLE_GENESIS_CORE_INSPECTOR,
  );
  const { isEnabled: isGenesisCoreInspectorActive, toggleInspector } =
    useGenesisCoreInspector();
  if (isGenesisCoreInspectorEnabled) {
    userDropdownConfig.unshift([
      {
        text: $tifelse(
          isGenesisCoreInspectorActive,
          'DISABLE_GENESIS_CORE_INSPECTOR',
          'ENABLE_GENESIS_CORE_INSPECTOR',
        ),
        to: '',
        onClick: () => {
          toggleInspector();
        },
      },
    ]);
  }

  return (
    <Navbar
      userDropdownConfig={userDropdownConfig}
      brandingInfo={brandingInfo}
    />
  );
});
