import {
  AUTH0_CLIENT_CONNECTION,
  StandaloneAuthConfig,
} from '@highmark-web/highmark-common';

// Standalone (not embedded in mobile) Auth Configuration
export const STANDALONE_AUTH_CONFIG: StandaloneAuthConfig = {
  clientOptions: {
    domain: import.meta.env.VITE_AUTH0_DOMAIN as string,
    client_id: import.meta.env.VITE_AUTH0_CLIENT_ID as string,
    redirect_uri: window.location.origin,
    connection: AUTH0_CLIENT_CONNECTION,
  },
  // Number of hours for which a session can be inactive before user is prompted to log out/continue
  // Note: Any changes to this value have to be synced with the idle_session_lifetime in the auth0 configuration.
  idleSessionLifetimeHours: 0.25,
};
