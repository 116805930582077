import { injectQualtricsScript } from '@highmark-web/highmark-common';

const QUALTRICS_LIVE_CHAT_INTERCEPTOR_ELEMENT_ID = 'ZN_0ojMqZvjF1O3YjQ';
const QUALTRICS_LIVE_CHAT_INTERCEPTOR_COOKIE_ID = 'QSI_S_ZN_0ojMqZvjF1O3YjQ';
const QUALTRICS_LIVE_CHAT_INTERCEPTOR_SRC =
  'https://zn0ojmqzvjf1o3yjq-highmark.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_0ojMqZvjF1O3YjQ';

export function injectLiveChatQualtricsScript() {
  injectQualtricsScript({
    elementId: QUALTRICS_LIVE_CHAT_INTERCEPTOR_ELEMENT_ID,
    cookieId: QUALTRICS_LIVE_CHAT_INTERCEPTOR_COOKIE_ID,
    src: QUALTRICS_LIVE_CHAT_INTERCEPTOR_SRC,
  });
}
