import { injectQualtricsScript } from '@highmark-web/highmark-common';

const QUALTRICS_HOME_PAGE_INTERCEPTOR_ELEMENT_ID = 'ZN_6MyYUv93aHhclIq';
const QUALTRICS_HOME_PAGE_INTERCEPTOR_COOKIE_ID = 'QSI_S_ZN_6MyYUv93aHhclIq';
const QUALTRICS_HOME_PAGE_INTERCEPTOR_SRC =
  'https://zn6myyuv93ahhcliq-highmark.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_6MyYUv93aHhclIq';

export function injectHomePageQualtricsScript() {
  injectQualtricsScript({
    elementId: QUALTRICS_HOME_PAGE_INTERCEPTOR_ELEMENT_ID,
    cookieId: QUALTRICS_HOME_PAGE_INTERCEPTOR_COOKIE_ID,
    src: QUALTRICS_HOME_PAGE_INTERCEPTOR_SRC,
  });
}
