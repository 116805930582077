import React from 'react';
import allMyHealthLogo from 'assets/allmyhealth-logo.svg';
import { useIntl } from '@leagueplatform/locales';
import { FooterLinkItems, LandingPage } from '@highmark-web/highmark-common';
import { usePreLoginFooterLinks } from 'hooks/use-pre-login-footer-links.hook';

export const BrandedLandingPage = () => {
  const { formatMessage } = useIntl();
  const landingGreeting = formatMessage({ id: 'GREETINGS_SUBHEADING' });
  const buttonText = formatMessage({ id: 'CONTINUE_TO_APP' });
  const authErrorText = formatMessage({ id: 'ERROR_CAPTION_BACKEND' });
  const { footerLinks } = usePreLoginFooterLinks();

  return (
    <LandingPage
      logo={allMyHealthLogo}
      landingGreeting={landingGreeting}
      buttonText={buttonText}
      authErrorText={authErrorText}
      footer={<FooterLinkItems linkItems={footerLinks} />}
    />
  );
};
