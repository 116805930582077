// Health Profile asset overrides
import AvatarIcon from 'assets/health-profile/avatar.svg';
import UpdateProfileImage from 'assets/health-profile/update-profile.svg';
// Highmark common asset config
import ErrorIcon from 'assets/error-icon.svg';
import PrivacyImage from 'assets/privacy.svg';
import LeaveWarningImage from 'assets/leave-or-exit-warning.svg';
// Journey asset overrides
import EmptyStatePrograms from 'assets/journey/progress-empty.svg';
import EmptyStateRest from 'assets/journey/rest-day.svg';
import ChallengesHighFive from 'assets/journey/challenges-high-five.svg';
import ChallengesMindfulMan from 'assets/journey/challenges-mindful-man.svg';
import ChallengesTrackWearables from 'assets/journey/challenges-track-wearables.svg';
import EmptyStateAchievements from 'assets/journey/empty-state-achievements.svg';
import CelebrationCyclingAnimation from 'components/animations/celebration-cycling-animation';
// Wallet asset overrides
import ClaimsEntry from 'assets/wallet/claims-entry-icon.svg';
// Rewards Asset Overrides
import CelebrationConfettiAnimation from 'components/animations/celebration-confetti-animation';
import CelebrationBadgeAnimation from 'components/animations/celebration-badge-animation';
// Messaging asset overrides
import DocumentIcon from 'assets/messaging/document-icon.svg';
import AddDocumentIcon from 'assets/messaging/add-document-icon.svg';
import {
  GarbageBin,
  JourneyHeaderBackground,
} from '@highmark-web/highmark-common';
// About Me asset overrides
import NoHealthMetricData from 'assets/about-me/dashboard-no-health-metric-data.svg';
import DashboardActivityIcon from 'assets/about-me/dashboard-activity-icon.svg';
import DashboardBodyIcon from 'assets/about-me/dashboard-body-icon.svg';

const HEALTH_PROFILE_ASSETS = {
  APP_HAND: UpdateProfileImage,
  PROFILE_AVATAR: AvatarIcon,
};

const HIGHMARK_COMMON_ASSETS = {
  HIGHMARK_PRIVACY_IMAGE: PrivacyImage,
  HIGHMARK_EXIT_WARNING_IMAGE: LeaveWarningImage,
};

const REWARDS_ASSETS = {
  ACHIEVEMENT_CONFETTI_ANIMATION: CelebrationConfettiAnimation,
  ACHIEVEMENT_BADGE_ANIMATION: CelebrationBadgeAnimation,
};

const MESSAGING_ASSETS = {
  MESSAGING_DOCUMENT_ICON: DocumentIcon,
  MESSAGING_ADD_DOCUMENT_ICON: AddDocumentIcon,
};

const JOURNEY_ASSETS = {
  ACTIVITIES_CYCLING_ANIMATION: CelebrationCyclingAnimation,
  EMPTY_STATE_CONFIGURABLE_ACTIVITIES: ' ',
  EMPTY_STATE_REST: EmptyStateRest,
  EMPTY_STATE_PROGRAMS: EmptyStatePrograms,
  GARBAGE_ICON: GarbageBin,
  JOURNEY_HEADER_BACKGROUND_IMAGE: JourneyHeaderBackground,
  CHALLENGES_HIGH_FIVE: ChallengesHighFive,
  CHALLENGES_TRACK_WEARABLES: ChallengesTrackWearables,
  CHALLENGES_TRACK_HEALTH_DATA: ChallengesTrackWearables,
  CHALLENGES_MINDFUL_MAN: ChallengesMindfulMan,
  EMPTY_STATE_ACHIEVEMENTS: EmptyStateAchievements,
  DEVICE_CONNECTION_ERROR_ICON: ErrorIcon,
  CONNECT_DEVICES: ChallengesTrackWearables,
};

const WALLET_ASSETS = {
  CLAIMS_ENTRY: ClaimsEntry,
};

const WEB_COMMON_ASSETS = {
  WEB_COMMON_ERROR_STATE_IMAGE: ErrorIcon,
};

const ABOUT_ME_ASSETS = {
  DASHBOARD_NO_HEALTH_METRIC_DATA: NoHealthMetricData,
  DASHBOARD_ACTIVITY_ICON: DashboardActivityIcon,
  DASHBOARD_BODY_ICON: DashboardBodyIcon,
};

export const ASSETS_CONFIG = {
  ...HEALTH_PROFILE_ASSETS,
  ...HIGHMARK_COMMON_ASSETS,
  ...REWARDS_ASSETS,
  ...MESSAGING_ASSETS,
  ...JOURNEY_ASSETS,
  ...WALLET_ASSETS,
  ...WEB_COMMON_ASSETS,
  ...ABOUT_ME_ASSETS,
};
